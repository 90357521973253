import type { Model } from '@/pages/section/sectionAdmin/model.tsx';
import { SearchListStructure } from '@/utils';
import { Button, Checkbox, Modal, Radio } from 'antd';
import { observer } from 'mobx-react';
import React, { useMemo } from 'react';

const ChooseEmployer = observer(({ store }: { store: Model }) => {
  const { chooseUserModal, onChangeModal, chooseUserStore, onChooseCopy, chooseCopy, onRecommend, chooseCardIds } =
    store;

  return (
    <Modal
      width={1200}
      title="选择雇主"
      open={chooseUserModal}
      onCancel={() => onChangeModal('chooseUserModal', false)}
      onClose={() => onChangeModal('chooseUserModal', false)}
      footer={() => {
        return (
          <div className="flex items-center justify-between">
            <Checkbox
              checked={chooseCopy}
              onChange={(e) => onChooseCopy(e.target.checked)}
            >
              复制切片链接
            </Checkbox>

            <div>
              <Button
                onClick={() => onChangeModal('chooseUserModal', false)}
                className="mr-2"
                type="default"
              >
                取消
              </Button>
              <Button
                className="mr-2"
                type="primary"
                onClick={() => onRecommend()}
              >
                立即推荐
              </Button>
            </div>
          </div>
        );
      }}
    >
      <div className="mt-2 flex w-full">
        <SearchListStructure
          style={{ height: '1000px', width: '100%' }}
          store={chooseUserStore.mainStore}
        />
      </div>
    </Modal>
  );
});

export default ChooseEmployer;
