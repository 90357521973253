import type { Model } from '@/pages/section/sectionAdmin/model.tsx';
import { Input, Modal } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

export const ReasonEdit = observer(({ store }: { store: Model }) => {
  const { reasonEditModal, changeReason, recommendReason, onChangeModal, onKeepReason, reasonEditModalTitle } = store;
  return (
    <Modal
      title={reasonEditModalTitle}
      open={reasonEditModal}
      onCancel={() => onChangeModal('reasonEditModal', false)}
      onOk={() => onKeepReason()}
    >
      <div className="mt-6 flex pb-4">
        推荐理由:
        <Input.TextArea
          value={recommendReason}
          maxLength={50}
          showCount={true}
          onChange={(e) => {
            changeReason(e.target.value);
          }}
          autoSize={{ minRows: 4 }}
          className="flex-1 ml-2"
        />
      </div>
    </Modal>
  );
});
