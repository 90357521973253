import { Modal } from 'antd';
import { action, observable } from 'mobx';
import { observer } from 'mobx-react';
import React from 'react';

interface Options {
  url?: string;
  coverUrl?: string;
}

export class VideoModalStore {
  @observable public open = false;
  @observable.ref options: Options = {};
  @action public handleOpen = (options: Options) => {
    this.open = true;
    this.options = options || {};
  };
  @action public handleClose = () => {
    this.open = false;
    this.options = {};
  };
}

export const VideoModal = observer(
  ({ videoModalStore: { open, options, handleClose } }: { videoModalStore: VideoModalStore }) => {
    return (
      <Modal
        footer={null}
        onCancel={handleClose}
        destroyOnClose={true}
        open={open}
        maskClosable={true}
        closable={true}
        centered={true}
      >
        {/* biome-ignore lint/a11y/useMediaCaption: <explanation> */}
        <video
          autoPlay={true}
          className={'mt-8'}
          controls={true}
          preload="auto"
          poster={options.coverUrl}
          src={options.url}
        />
      </Modal>
    );
  },
);
