import type { Model } from '@/pages/section/sectionAdmin/model.tsx';
import { Modal, TreeSelect } from 'antd';
import { observer } from 'mobx-react';
import React from 'react';

export const TagsEdit = observer(({ store }: { store: Model }) => {
  const { tagsModal, allTags, chooseTags, onSelectTag, onChangeModal, onKeepTags, tagsModalTitle } = store;

  return (
    <Modal
      title={tagsModalTitle}
      open={tagsModal}
      onCancel={() => onChangeModal('tagsModal', false)}
      onOk={onKeepTags}
    >
      <div className="mt-6 flex items-center pb-4">
        添加切片标签
        <TreeSelect
          value={chooseTags}
          multiple={true}
          treeCheckable={true}
          showCheckedStrategy={'SHOW_CHILD'}
          showSearch={true}
          onChange={onSelectTag}
          className="flex-1 ml-2"
          treeData={allTags}
          filterTreeNode={(inputValue: string, treeNode: any) => {
            return treeNode.title.toLowerCase().indexOf(inputValue.toLowerCase()) >= 0;
          }}
        />
      </div>
    </Modal>
  );
});
