import type React from 'react';
import { useEffect } from 'react';
import { useState } from 'react';

import { ArrowDownOutlined, ArrowUpOutlined, SwapOutlined } from '@ant-design/icons';

interface SortComponentProps {
  items: Array<{
    label: string;
    value: string;
  }>;
  sidx?: string;
  sord?: 'desc' | 'asc' | '' | null;
  onSort?: (sidx: string, sord: 'desc' | 'asc' | '') => void;
}

const SortComponent: React.FC<SortComponentProps> = ({ items, onSort }) => {
  // 第一次的时候不让跟新
  const [isFirst, setIsFirst] = useState<boolean>(true);
  const [sidx, setSidx] = useState<string>('');
  const [sord, setSord] = useState<'desc' | 'asc' | ''>(null);

  const handleSort = (key: string) => {
    if (key === sidx) {
      let newSord: any = '';
      if (!sord) {
        newSord = 'asc';
      } else {
        if (sord === 'asc') {
          newSord = 'desc';
        } else {
          newSord = '';
        }
      }
      if (newSord == '') {
        setSidx('');
      }

      setSord(newSord);
      return;
    }
    setSidx(key);
    setSord('asc');
  };

  // biome-ignore lint/correctness/useExhaustiveDependencies: <explanation>
  useEffect(() => {
    if (isFirst) {
      setIsFirst(false);
      return;
    }
    onSort ? onSort(sidx, sord) : null;
  }, [sord, sidx]);

  return (
    <div className="list-disc pl-6 flex">
      {items.map((item, index) => (
        <div
          onClick={() => handleSort(item.value)}
          key={item + String(index)}
          className="mb-1 mr-3 text-14"
          style={{ color: item.value === sidx ? '#3a6ef8' : '#4e546e' }}
        >
          <span className="mr-0.5">{item.label}</span>

          {sidx === item.value ? (
            sord === 'asc' ? (
              <ArrowUpOutlined />
            ) : (
              <ArrowDownOutlined />
            )
          ) : (
            <SwapOutlined className="rotate-90 text-normal" />
          )}
        </div>
      ))}
    </div>
  );
};

export default SortComponent;
