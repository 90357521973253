import { API } from '@/pages/employer/employerManagement/api.ts';
import { SearchListModal, request } from '@/utils';
import type { BaseData, IMainSubStructureModel, NormalProgrammeParams } from '@/utils';
import { Space, Tag } from 'antd';
import { action, observable } from 'mobx';
import React from 'react';

export default class ChooseEmployerModel {
  @observable public filterSet: Partial<NormalProgrammeParams> = {
    filterItems: [
      {
        type: 'input',
        field: 'name',
        label: '雇主昵称',
        placeholder: '请输入',
        span: 8,
      },
      {
        type: 'input',
        field: 'companyName',
        label: '公司名称',
        placeholder: '请输入',
        span: 8,
      },
      {
        type: 'input',
        field: 'butlerName',
        label: '招聘管家',
        placeholder: '请输入',
        span: 8,
      },
    ],
  };

  public grid: IMainSubStructureModel = {
    buttons: [],
    grid: {
      rowHeight: (row) => {
        const subscribeAnchorRuleVO = row.row.subscribeAnchorRuleVO;
        // subscribeAnchorRuleVO可能为空值
        if (subscribeAnchorRuleVO && Object.keys(subscribeAnchorRuleVO).length) {
          return 100;
        }
        return 40;
      },
      columns: [
        {
          key: 'name',
          name: '雇主昵称',
        },
        {
          key: 'companyName',
          name: '公司名称',
        },
        {
          key: 'butlerName',
          name: '管家姓名',
          width: 100,
        },
        {
          key: 'subscribeAnchorRuleVO',
          name: '订阅信息',
          width: 200,
          formatter: ({ row }) => {
            const { cityList, liveTypeList, minGmv, maxGmv, minPeople, maxPeople } = row.subscribeAnchorRuleVO || {};
            return (
              <Space className="flex  flex-col  items-start h-full">
                {cityList?.length ? (
                  <p className="leading-15">
                    工作城市：
                    {cityList.map((v) => (
                      <Tag key={v.id}>{v.name}</Tag>
                    ))}
                  </p>
                ) : null}
                {liveTypeList?.length ? (
                  <p className="leading-15">
                    直播品类：
                    {liveTypeList.map((v) => (
                      <Tag key={v.id}>{v.name}</Tag>
                    ))}
                  </p>
                ) : null}
                {minGmv || maxGmv ? (
                  <p className="leading-15">
                    单场最高GMV：{minGmv || '未知'} - {maxGmv || '未知'}
                  </p>
                ) : null}
                {minPeople || maxPeople ? (
                  <p className="leading-15">
                    单场同时在线人数：{minPeople || '未知'} - {maxPeople || '未知'}
                  </p>
                ) : null}
              </Space>
            );
          },
        },
        {
          key: 'onLinePositionCount',
          name: '职位数/上线职位数',
        },
        {
          key: 'inPersonCount',
          name: '到面次数/赠送次数',
          width: 150,
          formatter: ({ row }) => `${row.inPersonCount}次/${row.giftCount}个`,
        },
      ].map((v) => ({
        resizable: true,
        sortable: false,
        ...v,
      })),
      rows: [],
      primaryKeyField: 'id',
      sortByLocal: false,
      showCheckBox: true,
      showEmpty: true,
      showPager: true,
      showGridOrderNo: true,
    },
    pageId: new URLSearchParams(window.location.search)?.get('pageId'),
    hiddenSubTable: true,
    api: {
      onQuery: (params: Record<string, any>) => {
        const { filterParams, ...rest } = params;
        const postParams = {
          ...filterParams,
          ...rest,
        };

        for (const k in postParams) {
          if (Array.isArray(postParams[k])) {
            postParams[k] = postParams[k].toString();
          }
        }
        if (postParams.createTime) {
          postParams.createTimeBegin = postParams.createTime.split(',')[0];
          postParams.createTimeEnd = postParams.createTime.split(',')[1];
          // biome-ignore lint/performance/noDelete: <explanation>
          delete postParams.createTime;
        }
        return request<BaseData<any>>({
          url: API.queryEmployerPage,
          method: 'POST',
          data: { ...postParams },
        }).then((res) => {
          this.mainStore.grid.collectData = [
            {
              name: '总量',
              value: res?.data?.total,
            },
          ];
          return res;
        });
      },
    },
  };

  public mainStore = new SearchListModal({
    programme: this.filterSet,
    grid: this.grid,
  });
}
