import AnchorInfoModal from '@/compnents/anchorInfo';
import SortComponent from '@/compnents/sortComponent';
import { VideoModal } from '@/compnents/videoModal.tsx';
import ChooseEmployer from '@/pages/section/sectionAdmin/components/chooseEmployer';
import { NormalProgrammeComponent, RenderByPermission, getPageId } from '@/utils';
import { Button, Checkbox, Pagination, Spin } from 'antd';
import { observer } from 'mobx-react';
import { useMemo } from 'react';
import ModelCard from './components/modelCard';
import { ReasonEdit } from './components/reasonEdit';
import { SectionCard } from './components/sectionCard';
import { TagsEdit } from './components/tagsEdit';
import { Model } from './model';

const SectionAdmin = observer(() => {
  const store = useMemo(() => new Model(), []);
  const {
    filterSet,
    openRecommend,
    loading,
    cardList,
    pageParams,
    onPageChange,
    onCheckCard,
    chooseCardIds,
    chooseAllCard,
    checkedAll,
    openEditTags,
    openReasonEdit,
    onSort,
    videoModalStore,
    copyLinkCell,
    onModelCardDownload,
    anchorInfoModel,
  } = store;
  return (
    <div className="bg-[#F0F3F8] flex flex-col h-screen">
      <div className="bg-white pl-8 pr-8">
        <div className="pb-6 pt-6">
          <NormalProgrammeComponent store={filterSet} />
        </div>
      </div>

      <div className="mt-3 bg-white flex-1 flex flex-col">
        <div className="pl-2 pr-2 h-[62px] flex items-center">
          <Checkbox
            checked={checkedAll}
            onChange={chooseAllCard}
          >
            全选{chooseCardIds.length ? `(${chooseCardIds.length})` : ''}
          </Checkbox>

          <RenderByPermission permissionId={`${getPageId()}_66`}>
            <Button
              className="mr-3"
              size="small"
              onClick={() => {
                openRecommend?.();
              }}
            >
              批量推荐切片
            </Button>
          </RenderByPermission>

          <RenderByPermission permissionId={`${getPageId()}_56`}>
            <Button
              type="default"
              className="mr-3"
              size="small"
              onClick={() => {
                openEditTags?.();
              }}
            >
              批量切片标签
            </Button>
          </RenderByPermission>

          <RenderByPermission permissionId={`${getPageId()}_53`}>
            <Button
              type="default"
              className="mr-3"
              size="small"
              onClick={() => {
                openReasonEdit?.();
              }}
            >
              批量推荐理由
            </Button>
          </RenderByPermission>
          <div>
            <SortComponent
              onSort={onSort}
              items={[
                { value: 'create_time', label: '最新' },
                { value: 'viewCount', label: '最热' },
              ]}
              sord={pageParams.sord}
              sidx={pageParams.sidx}
            />
          </div>
        </div>
        <Spin
          tip="Loading..."
          spinning={loading}
        >
          <div className="overflow-y-scroll flex flex-wrap h-[1080px]">
            {}
            {cardList?.map((item) => {
              const isCheck = chooseCardIds.includes(item.opusId);
              return (
                <div
                  key={item.opusId}
                  className="w-[332px] mr-2 ml-2 mb-2"
                >
                  <SectionCard
                    onImgClick={() => videoModalStore.handleOpen({ url: item.sliceUrl, coverUrl: item.coverUrl })}
                    onChecked={onCheckCard}
                    isChecked={isCheck}
                    cell={item}
                    onReasonEdit={() => openReasonEdit(item.opusId)}
                    onTagEdit={() => openEditTags(item.opusId)}
                    onRecommend={() => openRecommend(item.opusId)}
                    onCopy={() => copyLinkCell(item)}
                    onModelCardDownload={() => onModelCardDownload(item)}
                    onAnchorInfoModel={() => anchorInfoModel.onShow(String(item.userId))}
                  />
                </div>
              );
            })}
          </div>
        </Spin>

        <div className="pb-4 flex justify-center pt-2">
          <Pagination
            current={pageParams.pageNum}
            pageSize={pageParams.pageSize}
            onChange={(pageNum, pageSize) => onPageChange(pageNum, pageSize)}
            total={pageParams.total}
            showTotal={(total) => `共${total}条记录`}
          />
        </div>
      </div>

      <TagsEdit store={store} />
      <ReasonEdit store={store} />
      <ChooseEmployer store={store} />
      <VideoModal videoModalStore={videoModalStore} />
      <ModelCard store={store.myModelCardStore} />
      <AnchorInfoModal store={store.anchorInfoModel} />
    </div>
  );
});
export default SectionAdmin;
