import { getStaticResourceUrl } from './helper';

export const api = {
  // 静态资源
  oss: getStaticResourceUrl('pc/ts/egenie-common'),
  //标签分类操作
  category: {
    // 添加分类
    add: '/pbb/platform/sys/tag/category/add',
    //删除分类
    delete: '/pbb/platform/sys/tag/category/delete',
    //分类明细
    detail: '/pbb/platform/sys/tag/category/detail',
    //编辑分类
    edit: '/pbb/platform/sys/tag/category/edit',
    //分页查询
    getList: '/pbb/platform/sys/tag/category/list/page',
  },
  //标签操作
  tag: {
    //添加标签
    add: '/pbb/platform/sys/tag/lib/add',
    //删除标签
    delete: '/pbb/platform/sys/tag/lib/delete',
    //标签明细
    detail: '/pbb/platform/sys/tag/lib/detail',
    //编辑标签
    edit: '/pbb/platform/sys/tag/lib/edit',
    //分页查询
    getList: '/pbb/platform/sys/tag/lib/list/page',
    //查询全部标签树
    getAllLibList: '/pbb/platform/sys/tag/lib/list/all',
  },
  system: {
    //获取字典map
    getDictMap: '/pbb/platform/anon/system/sysDict/getDictMap',
  },
};
