import { API } from '@/compnents/anchorInfo/api.ts';
import type { UserVO } from '@/compnents/anchorInfo/type.ts';
import { type BaseData, request } from '@/utils';
import { action, observable } from 'mobx';

export default class AnchorInfoModel {
  @observable public visible = false;
  @observable public userVo: UserVO = null;
  //查询职位详情
  getAnchorDetail = async (id: string) => {
    const results = await request<BaseData<UserVO>>({ url: API.getAnchorDetail, method: 'post', data: { id } });
    this.userVo = results.data;
  };
  @action
  onShow = async (userId: string) => {
    await this.getAnchorDetail(userId);
    this.visible = true;
  };

  @action
  onClose = () => {
    this.userVo = null;
    this.visible = false;
  };
}
