import { Modal } from 'antd';
import dayjs from 'dayjs';
import { observer } from 'mobx-react';
import React from 'react';
import { RenderByPermission, requireSource } from '../../utils';
import styles from './index.less';
import type AnchorInfoModel from './model';

const AnchorInfoModal = ({ store }: { store: AnchorInfoModel }) => {
  const pageId = new URLSearchParams(window.location.search)?.get('pageId');
  const { onClose, userVo, visible } = store;
  if (!userVo) {
    return null;
  }
  const {
    avatar,
    name,
    nickName,
    mobile,
    channelName,
    accountStatus,
    serviceObjectList,
    createTime,
    invitedCount,
    interviewCount,
    interviewedCount,
    signUpCount,
    brokerName,
    mediumName,
    inviteName,
    tagList,
    id,
  } = userVo;
  const renderServiceItem = () => {
    return serviceObjectList.map((item) => {
      const { serviceName, validEndTime } = item;
      return (
        <div
          className={'flex flex-col gap-y-3'}
          key={serviceName}
        >
          <div className="text-heading text-12">服务类型：{serviceName}</div>
          <div className="text-heading text-12">
            服务到期时间：{validEndTime ? dayjs(validEndTime).format('YYYY-MM-DD HH:mm:ss') : '-'}
          </div>
        </div>
      );
    });
  };
  return (
    <Modal
      open={visible}
      className={styles.page}
      width={800}
      onCancel={onClose}
      maskClosable={true}
      footer={null}
    >
      <div className="flex flex-row gap-x-4  flex-1">
        <div>
          <img
            src={avatar}
            className={`${styles.img} rounded-1`}
            alt=""
          />
          <div className="flex flex-row items-center mt-3 flex-1 justify-between ">
            <div className="flex flex-row items-center flex-1">
              <div className="text-heading text-14">{name || nickName}</div>
              {serviceObjectList && serviceObjectList.length > 0 && (
                <img
                  src={requireSource('/common/payStatus.png')}
                  alt=""
                  className="w-[49px] h-[18px] ml-2"
                />
              )}
            </div>
            <RenderByPermission permissionId={`${pageId}_21`}>
              <div
                className="text-primary text-12"
                onClick={() => {
                  window.top.RingPermission.openTab(`/pbb-pc-management/anchorDetail/${id}`, '67', '主播详情');
                }}
                style={{ cursor: 'pointer' }}
              >
                {'查看简历 >'}
              </div>
            </RenderByPermission>
          </div>
          <div className="text-[#4E546E] text-12 mt-2 mb-4">{mobile}</div>
          <div className="flex flex-row items-center">
            <div className="text-heading text-12">帐号状态：</div>
            <div className="flex flex-row items-center">
              <div className={`w-1.5 h-1.5 ${accountStatus === 0 ? 'bg-[#52C41A]' : 'bg-[#FF4D4F]'} rounded-1.5`} />
              <div className="text-heading text-12 ml-2">{accountStatus === 0 ? '正常' : '已封禁'}</div>
            </div>
          </div>
          <div className="flex flex-row items-center mt-3">
            <div className="text-heading text-12">注册来源：</div>
            <div className="text-heading text-12">{channelName}</div>
          </div>
          <div className="flex flex-row items-center mt-3">
            <div className="text-heading text-12">注册推荐人：</div>
            <div className="text-heading text-12">{inviteName}</div>
          </div>
          <div className="flex flex-row items-center mt-3">
            <div className="text-heading text-12">注册时间：</div>
            <div className="text-heading text-12">{dayjs(createTime).format('YYYY-MM-DD HH:mm:ss')}</div>
          </div>
        </div>
        <div className="flex flex-col flex-1">
          <div className="text-heading text-14 font-500">平台信息</div>
          <div className="flex flex-col mt-6 gap-y-1">
            <div className="flex flex-col gap-y-3 px-2 py-4 border-solid border-[1px] border-[#CBCEDB] rounded-1">
              <div className="text-12 text-heading font-500">服务信息</div>
              {serviceObjectList && serviceObjectList.length > 0 ? (
                renderServiceItem()
              ) : (
                <div className="text-12 text-heading">免费服务</div>
              )}
            </div>
            <div className="flex flex-col gap-y-3 px-2 py-4 border-solid border-[1px] border-[#CBCEDB] rounded-1">
              <div className="text-12 text-heading font-500">求职数据</div>
              <div className="flex flex-row items-center flex-1">
                <div className="text-heading text-12 flex-1">被邀约数：{invitedCount || 0}</div>
                <div className="text-heading text-12 flex-1">报名数：{signUpCount || 0}</div>
              </div>
              <div className="flex flex-row items-center">
                <div className="text-heading text-12 flex-1">约面数：{interviewCount || 0}</div>
                <div className="text-heading text-12 flex-1">到面数：{interviewedCount || 0}</div>
              </div>
            </div>
            <div className="flex flex-col gap-y-3 px-2 py-4 border-solid border-[1px] border-[#CBCEDB] rounded-1">
              <div className="text-12 text-heading font-500">标签</div>
              {tagList && tagList.length > 0 ? (
                <div className="flex flex-row items-center flex-1 gap-1 flex-wrap">
                  {tagList?.map((item) => {
                    return (
                      <div
                        className="text-primary text-12 px-2 bg-[#1D53D91A] border-[#3A6EF866] border-[1px] rounded-0.5"
                        key={item.libId}
                      >
                        {item.libName}
                      </div>
                    );
                  })}
                </div>
              ) : (
                <div className="text-12 text-heading">暂无标签</div>
              )}
            </div>
          </div>
        </div>
      </div>
    </Modal>
  );
};
export default observer(AnchorInfoModal);
