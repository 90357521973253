import { requireSource } from '@/utils';

export const ASSETS = {
  COMMON: {
    LOGO: requireSource('/common/logo.png'),
    PAY_STATUS: requireSource('/common/payStatus.png'),
    FILE_PDF: requireSource('/common/file_pdf.png'),
    PLAY: requireSource('/common/play.png'),
  },
  LOGIN_BG: requireSource('/login/login_bg.png'),
  ANCHOR_DETAIL_AUTH_REFUSE: requireSource('/anchor_detail/auth_refuse.png'),
  ANCHOR_DETAIL_AUTH_PASS: requireSource('/anchor_detail/auth_pass.png'),
  ANCHOR_DETAIL_VIDEO_IMAGE_DELETE: requireSource('/anchor_detail/video_image_delete.png'),
  ANCHOR_DETAIL_VITA_DELETE: requireSource('/anchor_detail/vita_delete.png'),
};
