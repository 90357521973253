export const API = {
  // 查询主播列表
  queryAnchorList: '/pbb/platform/anchor/list',
  // 用户账号状态修改
  updateAccountStatus: '/pbb/platform/user/accountStatus/update',
  // 主播切片/形象照审核操作
  auditOpus: '/pbb/platform/anchor/opus/audit/operation',
  // 主播简历审核操作
  auditVita: '/pbb/platform/anchor/vita/audit/operation',
  // 主播同步播圈
  syncBoquan: '/pbb/platform/anchor/sync/boquan',
  //保存标签，经纪人，管家，切片等编辑标签都用这个接口
  saveRelationTag: '/pbb/platform/sys/tag/relation/save',
  //经纪人列表
  getBrokerList: '/pbb/platform/broker/list',
  //分页查询用户 userRole=6查询媒介角色
  getSysUserList: '/pbb/platform/sys/user/list/page',
  //批量添加标签，经纪人，管家，切片等编辑标签都用这个接口，这个接口会不删除已经存在的标签，会在原来的标签基础上添加参数中所传的标签
  batchAddRelation: '/pbb/platform/sys/tag/relation/add/batch',
};
