import type { AnchorDetail } from '@/pages/anchorDetail/types';
import { type BaseData, request, requireSource } from '@/utils';
import FileSaver from 'file-saver';
import html2canvas from 'html2canvas';
import { action, observable } from 'mobx';
import { Provider, inject, observer } from 'mobx-react';
interface ModelCardData {
  hasExperience: number;
  name: string;
  sex: string;
  age: string;
  height: string;
  weight: string;
  city: string;
  liveYear: number;
  gmv: number;
  onLineCount: number;
  oldLiveType: string;
  slice: string[];
  image: string[];
  playIcon: string;
  titleImage: string;
  baseInfo: string;
  list: { url: string; type: 'image' | 'slice' }[];
}
export class MyModelCardModel {
  // 下载图片

  @observable modelCardData: ModelCardData;
  @observable changeData: { index: number; type: 'image' | 'slice'; visible: boolean; url: string };
  @observable needUpdatePhotos: boolean;
  @observable wxCode: string;

  readonly templateStyle: {
    [key: number]: { element: { [key: number]: React.CSSProperties }; width: string; height: string };
  } = {
    1: {
      element: {
        0: {
          width: '375px',
          height: '500px',
          left: 0,
          top: 0,
        },
        1: {
          width: '375px',
          height: '500px',
          left: 0,
          top: '504px',
        },
      },
      width: '375px',
      height: '1004px',
    },
    2: {
      element: {
        0: {
          width: '375px',
          height: '500px',
          left: 0,
          top: 0,
        },
        1: {
          width: '169px',
          height: '243px',
          left: '4px',
          top: '504px',
        },
        2: {
          width: '194px',
          height: '243px',
          left: '177px',
          top: '504px',
        },
      },
      width: '375px',
      height: '747px',
    },
    3: {
      element: {
        0: {
          width: '375px',
          height: '500px',
          left: 0,
          top: 0,
        },
        1: {
          width: '111px',
          height: '166px',
          left: '4px',
          top: '504px',
        },
        2: {
          width: '124px',
          height: '166px',
          left: '119px',
          top: '504px',
        },
        3: {
          width: '124px',
          height: '166px',
          left: '247px',
          top: '504px',
        },
      },
      width: '375px',
      height: '670px',
    },
    4: {
      element: {
        0: {
          width: '245px',
          height: '327px',
          left: 0,
          top: 0,
        },
        1: {
          width: '127px',
          height: '162px',
          left: '248px',
          top: 0,
        },
        2: {
          width: '127px',
          height: '162px',
          left: '248px',
          top: '165px',
        },
        3: {
          width: '169px',
          height: '243px',
          left: '4px',
          top: '331px',
        },
        4: {
          width: '194px',
          height: '243px',
          left: '177px',
          top: '331px',
        },
      },
      width: '375px',
      height: '574px',
    },
    5: {
      element: {
        0: {
          width: '277px',
          height: '370px',
          left: 0,
          top: 0,
        },
        1: {
          width: '96px',
          height: '122px',
          left: '279px',
          top: 0,
        },
        2: {
          width: '96px',
          height: '122px',
          left: '279px',
          top: '124px',
        },
        3: {
          width: '96px',
          height: '122px',
          left: '279px',
          top: '248px',
        },
        4: {
          width: '169px',
          height: '243px',
          left: '4px',
          top: '374px',
        },
        5: {
          width: '194px',
          height: '243px',
          left: '177px',
          top: '374px',
        },
      },
      width: '375px',
      height: '617px',
    },
  };

  @observable mode: keyof this['templateStyle'] = 1;
  @observable needUpdateInfo: boolean;

  @action setUserData = (data: AnchorDetail) => {
    const userInfo = data;

    const hasExperience = userInfo?.hasExperience ?? null;
    // 姓名
    const name = userInfo?.name ?? userInfo.nickName ?? null;
    // 性别
    const sex = userInfo?.sex === 1 ? '男' : userInfo?.sex === 2 ? '女' : null;
    // 年龄
    const age = userInfo?.age ? `${userInfo?.age}岁` : null;
    // 身高
    const height = userInfo?.height ? `${userInfo?.height}cm` : null;
    // 体重
    const weight = userInfo?.weight ? `${userInfo?.weight}kg` : null;
    // 城市
    const city = userInfo?.userJobHuntVO?.cityName ?? null;
    // 直播年限
    const liveYear = userInfo?.workYear ?? null;
    // gmv
    const gmv = userInfo?.userJobHuntVO?.gmv ?? null;
    // 最高人数
    const onLineCount = userInfo?.userJobHuntVO?.onLineCount ?? null;
    // 播过的类目
    const oldLiveType = userInfo?.userJobHuntVO?.oldLiveTypeList
      ? userInfo?.userJobHuntVO?.oldLiveTypeList?.map((item) => item.name)?.join('/')
      : null;
    // 切片
    const slice = userInfo?.userOpusListVO?.sliceList
      ? userInfo?.userOpusListVO?.sliceList?.map((item) => item.coverUrl)
      : null;
    // 形象照
    const image = userInfo?.userOpusListVO?.imageList
      ? userInfo?.userOpusListVO?.imageList?.map((item) => item.fileUrl)?.slice(0, 5)
      : null;
    // 播放按钮图标
    const playIcon = requireSource('/canvas/icon-video.png');
    // 标题图片
    const titleImage = requireSource('/canvas/modelCardTitle.png');
    // 基础资料
    const baseInfo = [sex, height, weight, age, city].filter(Boolean).join(' | ');

    const [list, mode] = this.setTemplateList(image?.length || 0, image, slice);

    this.mode = mode;

    this.modelCardData = {
      name,
      sex,
      age,
      height,
      weight,
      city,
      liveYear,
      gmv,
      onLineCount,
      oldLiveType,
      slice,
      image,
      playIcon,
      titleImage,
      baseInfo,
      list,
      hasExperience,
    };
  };

  @action public downPic = async (name: string) => {
    const canvas2 = document.createElement('canvas');
    const _element = document.getElementById('modelCardElement');

    const w = Number.parseInt(window.getComputedStyle(_element).width, 10);
    const h = Number.parseInt(window.getComputedStyle(_element).height, 10);

    canvas2.width = w * 4;
    canvas2.height = h * 4;
    const context = canvas2.getContext('2d');
    context.imageSmoothingEnabled = false;
    context.scale(4, 4);

    const canvas = await html2canvas(_element, {
      canvas: canvas2,
      scale: 1,
      useCORS: true,
    });
    canvas.toBlob((blob) => {
      FileSaver.saveAs(blob, name);
    });
  };

  @action setTemplateList = (
    index: number,
    image: string[],
    slice: string[],
  ): [typeof this.modelCardData.list, number] => {
    const list: typeof this.modelCardData.list = [];
    let mode = 0;
    switch (index) {
      case 1:
        {
          mode = 1;
          list[0] = {
            url: image[0],
            type: 'image',
          };
          list[1] = {
            url: slice?.[0] || '',
            type: 'slice',
          };
        }

        break;

      case 2:
        {
          mode = 2;
          list[0] = {
            url: image[0],
            type: 'image',
          };
          list[1] = {
            url: slice?.[0] || '',
            type: 'slice',
          };
          list[2] = {
            url: image[1],
            type: 'image',
          };
        }
        break;
      case 3:
        {
          mode = 3;
          list[0] = {
            url: image[0],
            type: 'image',
          };
          list[1] = {
            url: slice?.[0] || '',
            type: 'slice',
          };
          list[2] = {
            url: image[1],
            type: 'image',
          };
          list[3] = {
            url: image[2],
            type: 'image',
          };
        }
        break;
      case 4:
        {
          mode = 4;
          list[0] = {
            url: image[0],
            type: 'image',
          };
          list[1] = {
            url: image[1],
            type: 'image',
          };
          list[2] = {
            url: image[2],
            type: 'image',
          };
          list[3] = {
            url: slice?.[0] || '',
            type: 'slice',
          };
          list[4] = {
            url: image[3],
            type: 'image',
          };
        }
        break;
      default: {
        if (index >= 5) {
          mode = 5;
          list[0] = {
            url: image[0],
            type: 'image',
          };
          list[1] = {
            url: image[1],
            type: 'image',
          };
          list[2] = {
            url: image[2],
            type: 'image',
          };
          list[3] = {
            url: image[3],
            type: 'image',
          };
          list[4] = {
            url: slice?.[0] || '',
            type: 'slice',
          };
          list[5] = {
            url: image[4],
            type: 'image',
          };
        }
      }
    }
    return [list, mode];
  };
  /** 微信二维码 */
  @action getWxCode = async (id: string) => {
    const env = {
      production: 'release',
      development: 'trial',
    };
    const anchorId = id;

    const nodeEnv = process.env.NODE_ENV as keyof typeof env;
    const envVersion = env[nodeEnv];
    const res = await request<BaseData<string>>({
      url: '/pbb/platform/anon/getUnlimitedQRCode',
      method: 'POST',
      data: {
        anchorId,
        channel: 6, //  6 主播主页
        envVersion,
        environment: 1, // 2 小程序
        parentUserId: anchorId,
        source: 2, // 2 企业端
      },
    });
    this.wxCode = res?.data;
  };
}
interface GridCard {
  store?: MyModelCardModel;
  showChange?: boolean;
  index?: number;
  item?: any;
  type?: 'slice' | 'image';
  mode?: number;
}

const MyModelCard = ({ store }: { store: MyModelCardModel }) => {
  return (
    <Provider store={store}>
      <div
        className="bg-[#F7F7F7] w-[375px] fixed left-[100%] top-[100%] z-[-10]"
        id="modelCardElement"
      >
        <div className="my-2 bg-white">
          <Header />
          <div
            className="relative"
            style={{
              width: `${store.templateStyle?.[store.mode]?.width}`,
              height: `${store.templateStyle?.[store.mode]?.height}`,
            }}
          >
            {store.modelCardData?.list?.map((item, index) => {
              return (
                <SliceImage
                  key={index.toString()}
                  item={item}
                  index={index}
                  mode={store.mode}
                />
              );
            })}
          </div>

          <Slogan />
        </div>
      </div>
    </Provider>
  );
};

/**
 * 顶部基础资料
 */
const Header = inject('store')(
  observer(({ store }: GridCard) => {
    const { modelCardData } = store;
    return (
      <div className="p-[62px_20px_16px] relative">
        <div className="flex items-center mb-4 font-600 text-24">
          <span>{modelCardData?.name}</span>
        </div>
        {modelCardData?.baseInfo && <div className="mb-4 text-12 text-[#232323]">{modelCardData?.baseInfo}</div>}
        {modelCardData?.hasExperience === 1 && (
          <div className="text-12 leading-18 text-[#666666]">
            {modelCardData?.liveYear >= 0 && <div>直播年限：{modelCardData?.liveYear}年</div>}
            {modelCardData?.gmv >= 0 && <div>单场最高GMV：{modelCardData?.gmv}元</div>}
            {modelCardData?.onLineCount >= 0 && <div>最高在线人数：{modelCardData?.onLineCount}人</div>}
            {modelCardData?.oldLiveType && <div>播过的类目：{modelCardData?.oldLiveType}</div>}
          </div>
        )}
        <div className="flex flex-col items-center absolute top-[56px] right-[20px]">
          <img
            src={store.wxCode}
            alt=""
            className="w-[82px] h-[82px]"
          />
          <div className="text-12 text-[#666666]">扫码查看我的切片</div>
        </div>
      </div>
    );
  }),
);

/**
 * 底部
 */
const Slogan = inject('store')(({ store }: GridCard) => {
  return (
    <div className="flex items-center justify-center py-3 ">
      <img
        className="w-[129px] h-[11px]"
        src={store.modelCardData?.titleImage}
        alt=""
      />
    </div>
  );
});

const SliceImage = inject('store')(
  observer(({ store, mode, item, index }: GridCard) => {
    return (
      <div
        className="absolute"
        style={store.templateStyle?.[mode]?.element?.[index]}
      >
        <img
          className="w-full h-full"
          src={item.url}
          alt=""
        />
        {item.type === 'slice' && (
          <img
            src={store.modelCardData?.playIcon}
            className="w-[28px] h-[28px] absolute top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%]"
            alt=""
          />
        )}
      </div>
    );
  }),
);

export default observer(MyModelCard);
